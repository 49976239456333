<template>
  <div id="news-view">
    <NavBar :active="'news'" />
    <div class="news-body">
      <img src="../../assets/News/DigitalTrust.jpg" alt="" class="news-img">
      <div class="news-title">
        IDChain.com Partners with Digital Trust Philippines to Enhance ID and KYC Verification for CEZA
      </div>
      <div class="news-content">
        <small>July 01, 2024</small>
        <p class="news-text">
          IDChain.com has entered into a strategic partnership with Digital Trust Philippines to deliver advanced ID, KYC (Know Your Customer), and liveness verification solutions aimed at preventing fraud within the Cagayan Economic Zone Authority (CEZA). Digital Trust, an Audit and Probity solution provider for CEZA, plays a crucial role in overseeing the registration of international companies applying for Fintech licenses under CEZA’s regulatory framework.
        </p>
        <p class="news-text">
          Digital Trust Philippines was awarded the exclusive contract in December 2023 to provide probity solutions for CEZA, a position it will hold until 2033. This contract allows Digital Trust to implement and manage an online registration platform for international companies seeking Fintech licenses under CEZA. To ensure maximum security and regulatory compliance, Digital Trust turned to IDChain.com’s cutting-edge technology for identity verification, which leverages AI and blockchain to deliver unparalleled accuracy and security.
        </p>
        <p class="news-text">
          Through this partnership, IDChain will deploy its industry-leading liveness verification technology, which confirms that individuals undergoing KYC are genuine and physically present during the verification process. This is a crucial step in preventing identity fraud and ensuring the integrity of CEZA’s Fintech license application process. The collaboration also integrates IDChain’s robust KYC platform to ensure businesses and individuals are fully verified before engaging with CEZA’s ecosystem.
        </p>
        <p class="news-text">
          Through this partnership, IDChain will deploy its industry-leading liveness verification technology, which confirms that individuals undergoing KYC are genuine and physically present during the verification process. This is a crucial step in preventing identity fraud and ensuring the integrity of CEZA’s Fintech license application process. The collaboration also integrates IDChain’s robust KYC platform to ensure businesses and individuals are fully verified before engaging with CEZA’s ecosystem.
        </p>
        <p class="news-text">
          As part of its role, Digital Trust Philippines has also implemented a comprehensive transaction monitoring and audit platform. This solution ensures full transparency of financial and operational activities within CEZA, allowing regulators to seamlessly audit and monitor all transactions. The addition of IDChain’s verification solutions further enhances Digital Trust’s ability to safeguard the integrity of CEZA’s financial ecosystem.
        </p>
        <p class="news-text">
          “We are excited to collaborate with Digital Trust in helping ensure that CEZA maintains a secure and compliant environment for Fintech companies,” said Liezl Pecson, Product Manager at IDChain. “Our AI-powered verification platform will ensure that both individuals and businesses meet the highest standards of authenticity and trust, which are essential in the fight against fraud.”
        </p>
        <p class="news-text">
          The partnership between IDChain and Digital Trust Philippines represents a significant step forward in the development of a secure and transparent framework for CEZA’s international Fintech ecosystem. By combining Digital Trust’s audit and probity expertise with IDChain’s cutting-edge identity verification technology, this collaboration aims to raise the bar for regulatory compliance and fraud prevention in the Philippines and beyond.
        </p>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import NavBar from '../../components/NavBar.vue'; // Import the Navbar component
import FooterComponent from '../../components/FooterComponent.vue';

export default {
  components: {
    NavBar,
    FooterComponent // Register the Navbar component
  },
}
</script>

<style scoped>
    .news-body {
        background:url(../../assets/Press/bg4.jpg);
        background-position-x:center;
        color:white;
        min-height:100vh;
        background-size:cover;
        padding:30px;
        text-align:center;
    }

    .news-img {
        align-self:center;
        width:70%;
    }

    .news-content {
      width:70%;
      text-align:left;
      margin:0 auto;
    }

    .news-title {
    font-family:'Poppins-Bold';
    font-size:30px;
    width:70%;
    margin:20px auto;
    margin-top:15px;
  }
</style>