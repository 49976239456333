import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/HomeView.vue';
import About from './views/AboutView.vue';
import Press from './views/PressView.vue';
import HowItWorks from './views/HowItWorksView.vue';
import OurPartner from './views/OurPartnerView.vue';
import News from './views/NewsView.vue';
import SolanaImplementation from './views/news/SolanaImplementation.vue';
import SolanaNetwork from './views/news/SolanaNetwork.vue';
import CoexstarEU from './views/news/CoexstarEU.vue';
import DigitalTrust from './views/news/DigitalTrust.vue';
import DeckView from '@/views/DeckView.vue';

const routes = [
  { path: '/', component: Home },
  { path: '/about', component: About },
  { path: '/press', component: Press },
  { path: '/how-it-works', component: HowItWorks },
  { path: '/our-partner', component: OurPartner },
  { path: '/news', component: News },

  // News 
  { path: '/idchain-to-implement-php-stablecoin', component: SolanaImplementation },
  { path: '/idchain-adds-solana-network', component: SolanaNetwork },
  { path: '/idchain-acquires-coexstar-eu', component: CoexstarEU },
  { path: '/idchain-partners-with-digital-trust', component: DigitalTrust },
  { path: '/deck', name: 'Deck', component: DeckView },
  // Add more routes here
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;