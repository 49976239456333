<template>
  <div id="news-view">
    <NavBar :active="'news'" />
    <div class="news-body">
      <img src="../../assets/News/CoexstarEU.jpg" alt="" class="news-img">
      <div class="news-title">
        IDChain.com Acquires Coexstar EU as a Client for Global Cryptocurrency Exchange Verification Services
      </div>
      <div class="news-content">
        <small>September 26, 2024</small>
        <p class="news-text">
          IDChain.com has successfully secured Coexstar EU, a leading player in the European cryptocurrency exchange market, as a client. This partnership marks a significant step in IDChain’s mission to provide cutting-edge ID verification services powered by AI and blockchain technology. Coexstar EU, which focuses on cryptocurrency exchanges globally, will now benefit from IDChain’s advanced liveness verification for individuals and business verification systems designed to combat fraud and enhance trust across the digital asset space.
        </p>
        <p class="news-text">
          As cryptocurrency exchanges continue to experience exponential growth, the need for reliable, secure, and compliant ID verification processes has become more critical than ever. By leveraging AI-powered verification and the immutable security of blockchain technology, IDChain offers a solution that ensures user authenticity while streamlining onboarding processes for global exchanges. This partnership with Coexstar EU reinforces IDChain’s position as a leader in the space.
        </p>
        <p class="news-text">
          “We are thrilled to be working with IDChain to implement their advanced ID verification solutions across our platforms,” said Hwakang (David) Yoo, CEO of Coexstar Global. “The integration of IDChain’s liveness verification and fraud prevention tools will not only help us meet regulatory requirements but will also give our users the assurance that their data and transactions are secure. This is crucial as we continue to expand our presence globally.”
        </p>
        <p class="news-text">
          IDChain’s liveness verification technology ensures that users are real, living individuals at the time of registration, which is a crucial step in preventing fraud and maintaining compliance with international regulations. The business verification system also allows Coexstar EU to vet corporate clients and prevent fraudulent entities from accessing their services. These features combine to offer a robust defense against fraudulent activities in the cryptocurrency exchange ecosystem.
        </p>
        <p class="news-text">
          “Partnering with Coexstar EU is a testament to IDChain’s commitment to delivering high-quality, secure, and scalable ID verification solutions,” said Liezl Pecson, Product Manager at IDChain. “Our AI-driven verification process, combined with blockchain security, allows for fast and reliable identification that will not only enhance Coexstar’s operations but also protect its users from the growing threat of fraud.”
        </p>
        <p class="news-text">
          The collaboration between IDChain and Coexstar EU is poised to reshape the way cryptocurrency exchanges handle verification and fraud prevention. With the rise in global regulations surrounding digital assets, this partnership ensures that Coexstar EU remains at the forefront of compliance while offering a seamless user experience.
        </p>
        <p class="news-text">
          This acquisition of Coexstar EU as a client underscores IDChain’s growing influence in the blockchain and digital identity sectors. Both companies are excited about the opportunities ahead, as they work together to provide more secure, transparent, and efficient solutions to the global cryptocurrency community.
        </p>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import NavBar from '../../components/NavBar.vue'; // Import the Navbar component
import FooterComponent from '../../components/FooterComponent.vue';

export default {
  components: {
    NavBar,
    FooterComponent // Register the Navbar component
  },
}
</script>

<style scoped>
    .news-body {
        background:url(../../assets/Press/bg4.jpg);
        background-position-x:center;
        color:white;
        min-height:100vh;
        background-size:cover;
        padding:30px;
        text-align:center;
    }

    .news-img {
        align-self:center;
        width:70%;
    }

    .news-content {
      width:70%;
      text-align:left;
      margin:0 auto;
    }

    .news-title {
    font-family:'Poppins-Bold';
    font-size:30px;
    width:70%;
    margin:20px auto;
    margin-top:15px;
  }
</style>