<template>
  <div id="news-view">
    <NavBar :active="'news'" />
    <div class="news-body">
      <img src="../../assets/News/SolanaImplementation.jpg" alt="" class="news-img">
      <div class="news-title">
        IDChain.com to Implement PHP Stablecoin and International Currency Solutions on Solana Network 
        for Payments and Remittances
      </div>
      <div class="news-content">
        <small>September 26, 2024</small>
        <p class="news-text">
          IDChain.com has announced the implementation of PHP Stablecoin and various international currencies on the Solana Network, 
          paving the way for an efficient and secure payment and remittance ecosystem. This initiative aims to support consumers, 
          businesses, and government agencies by streamlining cross-border remittances, product payments, and tax transactions 
          through a blockchain-based infrastructure. The partnership will leverage the capabilities of Coexstar EU and Digital 
          Trust Philippines to ensure the system operates with maximum security, transparency, and confidence.
        </p>
        <p class="news-text">
          With the growing demand for faster, more reliable financial solutions, IDChain.com is poised to transform the way payments and remittances are conducted. By introducing PHP Stablecoin alongside international currency options on the Solana blockchain, the platform will enable users to conduct real-time transactions with lower fees and enhanced security. This will be particularly beneficial for Overseas Filipino Workers (OFWs), allowing them to send remittances to their families with ease and peace of mind.
        </p>
        <p class="news-text">
          The collaboration between IDChain, Coexstar EU, and Digital Trust Philippines opens the door to a wide range of financial solutions. For OFWs, remittances will be more accessible and cost-efficient, while businesses can streamline their international product payments. Additionally, government agencies will be able to use this blockchain-powered solution to manage tax payments and monitor transactions with a higher level of accuracy and transparency.
        </p>
        <p class="news-text">
          One of the key advantages of Solana’s blockchain infrastructure is its ability to handle thousands of transactions per second, making it the ideal network for supporting high-volume payments and remittances. Its low transaction costs and scalability make it a practical solution for a wide range of users, from individual consumers to large-scale businesses and government agencies.
        </p>
        <p class="news-text">
          “The introduction of PHP Stablecoin and international currencies on the Solana Network allows us to provide a highly efficient, transparent, and secure financial ecosystem,” said Liezl Pecson, Product Manager of IDChain.com. “By working with our partners Coexstar EU and Digital Trust Philippines, we are confident that this solution will revolutionize remittances, product payments, and tax transactions for our users, both locally and internationally.”
        </p>
        <p class="news-text">
          IDChain.com’s partnership with Coexstar EU and Digital Trust Philippines ensures that these new financial solutions will be rolled out with security and compliance in mind. Coexstar EU will provide the necessary global infrastructure to support international payments, while Digital Trust Philippines will focus on ensuring that all transactions are fully auditable and meet regulatory standards. Together, they will bring transparency and trust to every layer of the payment and remittance process.
        </p>
        <p class="news-text">
          For government agencies, the implementation of blockchain technology offers unprecedented visibility into tax payments and financial transactions. The transparent nature of Solana’s blockchain enables real-time monitoring and auditing, ensuring that all payments are compliant and properly recorded. This level of transparency will improve governance and reduce the potential for fraud.
        </p>
        <p class="news-text">
          Businesses will also benefit from the use of stablecoins and international currency options, as it will enable them to manage product payments with greater efficiency and reduced costs. By utilizing blockchain for these transactions, companies can eliminate the delays and fees associated with traditional payment systems, creating a more streamlined and cost-effective solution for international trade.
        </p>
        <p class="news-text">
          IDChain.com’s comprehensive approach to payments and remittances demonstrates its commitment to creating a seamless financial ecosystem that works for everyone—from consumers and businesses to government institutions. The combined use of PHP Stablecoin and international currencies on Solana is just the beginning of a new era in financial services.
        </p>
        <p class="news-text">
          As the world continues to move toward digital currencies and blockchain technology, IDChain.com’s implementation of these solutions will ensure that users have access to the most secure, transparent, and efficient financial tools available. This partnership with Coexstar EU and Digital Trust Philippines will play a vital role in driving the adoption of blockchain-based payments and remittances across the globe.
        </p>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import NavBar from '../../components/NavBar.vue'; // Import the Navbar component
import FooterComponent from '../../components/FooterComponent.vue';

export default {
  components: {
    NavBar,
    FooterComponent // Register the Navbar component
  },
}
</script>

<style scoped>
    .news-body {
        background:url(../../assets/Press/bg4.jpg);
        background-position-x:center;
        color:white;
        min-height:100vh;
        background-size:cover;
        padding:30px;
        text-align:center;
    }

    .news-img {
        align-self:center;
        width:70%;
    }

    .news-content {
      width:70%;
      text-align:left;
      margin:0 auto;
    }

    .news-title {
    font-family:'Poppins-Bold';
    font-size:30px;
    width:70%;
    margin:20px auto;
    margin-top:15px;
  }
</style>