<template>
  <div id="news-view">
    <NavBar :active="'news'" />
    <div class="news-body">
      <img src="../../assets/News/SolanaNetwork.jpg" alt="" class="news-img">
      <div class="news-title">
        IDChain.com adds Solana Network for Blockchain Infrastructure to Support its ID KYC and Verification Platform
      </div>
      <div class="news-content">
        <small>September 02, 2024</small>
        <p class="news-text">
          IDChain.com has officially added the Solana Network as the blockchain infrastructure to power its innovative ID KYC and verification platform. After undergoing an extensive review and assessment of various blockchain technologies, the IDChain team determined that Solana was the optimal solution for their platform's needs. This decision highlights IDChain’s commitment to building a robust, scalable, and secure identity verification system for global users.
        </p>
        <p class="news-text">
          The selection process involved evaluating several blockchain options, taking into account critical factors such as transaction speed, network scalability, security protocols, and cost-efficiency. Solana stood out as a clear frontrunner for its unparalleled performance and ability to handle high throughput while maintaining low transaction fees. This will enable IDChain to offer seamless and reliable verification services, even at scale.
        </p>
        <p class="news-text">
          "Our goal was to find a blockchain solution that could not only support the current demands of IDChain’s verification services but also scale efficiently as our user base grows," said Ms. Liezl Pecson, Product Manager of IDChain.com. "Solana met all of our requirements, offering a perfect balance between speed, security, and affordability. We are excited about the potential this partnership holds for both IDChain and our users."
        </p>
        <p class="news-text">
          The partnership with Solana comes as IDChain nears the completion of its platform, with the full solution scheduled to be finalized by September 20. The integration of Solana into IDChain's architecture is expected to be completed before the end of September, marking a key milestone in the platform’s development.
        </p>
        <p class="news-text">
          The decision to adopt Solana aligns with IDChain’s vision of creating a fast, secure, and transparent verification platform that serves businesses, institutions, and individuals worldwide. Solana’s capability to handle thousands of transactions per second will allow IDChain to deliver verification services with minimal latency and at a fraction of the cost seen with other blockchain networks.
        </p>
        <p class="news-text">
          In addition to its technical strengths, Solana’s proven security protocols and decentralized infrastructure give IDChain the confidence needed to ensure that all user data is protected and verifiable across the network. This is critical in maintaining trust and compliance in an increasingly digital world where identity verification is paramount.
        </p>
        <p class="news-text">
          With the Solana integration in place, IDChain is poised to lead the industry in blockchain-based identity verification solutions. The successful collaboration is expected to open doors for future innovations and partnerships, further enhancing the platform's capabilities.
        </p>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import NavBar from '../../components/NavBar.vue'; // Import the Navbar component
import FooterComponent from '../../components/FooterComponent.vue';

export default {
  components: {
    NavBar,
    FooterComponent // Register the Navbar component
  },
}
</script>

<style scoped>
    .news-body {
        background:url(../../assets/Press/bg4.jpg);
        background-position-x:center;
        color:white;
        min-height:100vh;
        background-size:cover;
        padding:30px;
        text-align:center;
    }

    .news-img {
        align-self:center;
        width:70%;
    }

    .news-content {
      width:70%;
      text-align:left;
      margin:0 auto;
    }

    .news-title {
    font-family:'Poppins-Bold';
    font-size:30px;
    width:70%;
    margin:20px auto;
    margin-top:15px;
  }
</style>