<template>
    <div class="pdf-container">
        <iframe 
            :src="pdfUrl" 
            width="100%" 
            height="100%" 
            style="border: none;"
            title="PDF Viewer"
        ></iframe>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pdfUrl: '/deck.pdf', // Adjust the path if needed
        };
    },
};
</script>

<style scoped>
.pdf-container {
    position: fixed; /* Makes the container fill the viewport */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000; /* Ensure it is above other elements */
}
iframe {
    width: 100%;
    height: 100%;
}
</style>