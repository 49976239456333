<template>
  <div class="works-view">
    <NavBar :active="'how-it-works'"/>
    <div class="works-hero">
      <div class="hero-img">
        <img src="../assets/How it works/Asset 36.png" alt="">
      </div>
      <div class="hero-text">
        <div class="hero-title">
          WELCOME TO
          THE FUTURE.
        </div>
        <div class="hero-subtext">
          Your IDChain is your passport to the digital world.
        </div>
      </div>
    </div>
    <div class="documentation">
      <div class="documentation-title">Technical documentation</div>
      <div class="documentation-subtext">Here’s how IDChain works</div>
      <div class="documentation-img">
        <img src="../assets/How it works/graph.png" alt="">
      </div>
    </div>
    <div class="signup">
      <div class="signup-title">
        IDChain is a trusted leader in
        innovation relating to identification
        and know-your-customer. 
      </div>
      <div class="signup-subtext">
        The brand and its partners have been featured extensively in media.
      </div>
      <a href="https://app.idchain.com/signup"><button class="btn-signup">Signup</button></a>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import NavBar from '../components/NavBar.vue'; // Import the Navbar component
import FooterComponent from '../components/FooterComponent.vue';

export default {
  components: {
    NavBar,
    FooterComponent // Register the Navbar component
  },
  // Other component options
}
</script>

<style scoped>
  .works-view {
      background:url(../assets/How\ it\ works/bg3.jpg);
      background-position-x:center;
      color:white;
      background-size:cover;
  }

  .works-hero {
    padding:30px;
    display:flex;
    flex-wrap:wrap;
    justify-content:space-around;
  }

  .hero-img {
    width:600px;
  }

  .hero-img img {
    width:100%;
    height:auto;
  }

  .hero-text {
    width:500px;
    margin:auto 0;
  }

  .hero-title {
    font-size:60px;
    font-family:'Poppins-Bold';
    margin-bottom:20px;
  }

  .documentation {
    padding:30px;
    text-align:center;
  }

  .documentation-title {
    font-size:50px;
    color:#0b8fd8;
    font-family:Poppins-Bold;
  }

  .documentation-img {
    width:80%;
    margin:30px auto;
  }

  .documentation-img img {
    width:100%;
  }

  .signup {
    padding:30px 50px;
    text-align:center;
  }

  .signup-title {
    font-family:'Poppins-Bold';
    font-size:40px;
    width:70%;
    margin:0 auto;
  }

  .signup-subtext {
    margin:20px 0;
    font-size:20px;
  }

  .btn-signup,
    .btn-login {
        background-color: #4CAF50;
        border: none;
        color: white;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 5px;
        width:200px;
    }

    .btn-signup {
        background-color: transparent;
        border: none;
        color: white;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        margin-right:10px;
        cursor: pointer;
        /* Gradient Border */
        border: 2px solid transparent;
        border-color: #a268aa;
        border-image-slice: 1;
        border-radius: 5px; /* Apply border-radius */
    }

    @media screen and (max-width:1024px) {
      .hero-text {
        margin-top:20px;
        text-align:center;
        width:100%;
      }
    }

    @media screen and (max-width:450px) {
      .documentation-title, .hero-title {
        font-size:30px;
      }

      .signup-title {
        width:100%;
        font-size:25px;
      }

      .documentation-img {
        width:100%;
      }
    }
</style>