<template>
  <div class="news-view">
    <NavBar :active="'news'"/>
      <div class="news-content">
        <div class="news-block d-flex flex-wrap justify-content-center">
          <img src="../assets/News/SolanaImplementation.jpg" alt="" class="news-img">
          <div class="news-text">
            <h3 class="news-title">
              IDChain.com to Implement PHP Stablecoin and International Currency Solutions on Solana Network for 
              Payments and Remittances
            </h3>
            <small class="news">September 26, 2024</small>
            <p class="news-preview">
              IDChain.com has announced the implementation of PHP Stablecoin and various international currencies on the Solana 
              Network, paving the way for an efficient and secure payment and remittance ecosystem. This initiative aims to support 
              consumers, businesses, and government agencies by streamlining cross-border remittances, product payments, and tax 
              transactions through a blockchain-based infrastructure...
            </p>
            <a href="/idchain-to-implement-php-stablecoin"><button class="btn btn-readmore">Read More</button></a>
          </div>
        </div>
        <div class="news-block d-flex flex-wrap justify-content-center">
          <img src="../assets/News/CoexstarEU.jpg" alt="" class="news-img">
          <div class="news-text">
            <h3 class="news-title">
              IDChain.com Acquires Coexstar EU as a Client for Global Cryptocurrency Exchange Verification Services
            </h3>
            <small class="news">September 14, 2024</small>
            <p class="news-preview">
              IDChain.com has successfully secured Coexstar EU, a leading player in the European cryptocurrency exchange market, 
              as a client. This partnership marks a significant step in IDChain’s mission to provide cutting-edge ID verification 
              services powered by AI and blockchain technology...
            </p>
            <a href="/idchain-acquires-coexstar-eu"><button class="btn btn-readmore">Read More</button></a>
          </div>
        </div>
        <div class="news-block d-flex flex-wrap justify-content-center">
          <img src="../assets/News/SolanaNetwork.jpg" alt="" class="news-img">
          <div class="news-text">
            <h3 class="news-title">
              IDChain.com adds Solana Network for Blockchain Infrastructure to Support its ID KYC and Verification Platform
            </h3>
            <small class="news">September 02, 2024</small>
            <p class="news-preview">
              IDChain.com has officially added the Solana Network as the blockchain infrastructure to power its innovative ID 
              KYC and verification platform. After undergoing an extensive review and assessment of various blockchain 
              technologies, the IDChain team determined that Solana was the optimal solution for their platform's needs...
            </p>
            <a href="/idchain-adds-solana-network"><button class="btn btn-readmore">Read More</button></a>
          </div>
        </div>
        <div class="news-block d-flex flex-wrap justify-content-center">
          <img src="../assets/News/DigitalTrust.jpg" alt="" class="news-img">
          <div class="news-text">
            <h3 class="news-title">
              IDChain.com Partners with Digital Trust Philippines to Enhance ID and KYC Verification for CEZA
            </h3>
            <small class="news">July 01, 2024</small>
            <p class="news-preview">
              IDChain.com has entered into a strategic partnership with Digital Trust Philippines to deliver advanced ID, KYC 
              (Know Your Customer), and liveness verification solutions aimed at preventing fraud within the Cagayan Economic 
              Zone Authority (CEZA). Digital Trust, an Audit and Probity solution provider for CEZA, plays a crucial role in 
              overseeing the registration of international companies applying for Fintech licenses under CEZA’s regulatory 
              framework...
            </p>
            <a href="/idchain-partners-with-digital-trust"><button class="btn btn-readmore">Read More</button></a>
          </div>
        </div>
      </div>
    <FooterComponent />
  </div>
</template>

<script>
import NavBar from '../components/NavBar.vue'; // Import the Navbar component
import FooterComponent from '../components/FooterComponent.vue';

export default {
  components: {
    NavBar,
    FooterComponent // Register the Navbar component
  },
  // Other component options
}
</script>

<style scoped>
 .news-view {
    background:url(../assets/Press/bg4.jpg);
    background-position-x:center;
    color:white;
    min-height:100vh;
    background-size:cover;
  }

  .news-content {
    padding:30px;
    text-align:center;
    min-height:100vh;
  }

  .news-block {
    text-align:center;
    padding:10px;
    margin:10px;
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    width:100%;
  }

  .news-img {
    width:600px;
    height:auto;
    object-fit:contain;
    margin:10px;
  }

  .news-text {
    width:600px;
    text-align:left;
    margin:10px;
  }

  .news-title {
    font-family:'Poppins-Bold';
    font-size:30px;
  }

  .btn-readmore {
    background: #0d5e94;
    border: 2px #0d5e94 solid;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 0px;
    cursor: pointer;
    border-radius: 5px;
  }

  @media screen and (max-width:800px) {
    .news-img {
      width:90%;
    }
  }
  
</style>